.shepherd-has-title .shepherd-content .shepherd-header {
  @apply px-5 py-4;
}

.shepherd-title {
  @apply text-sm text-black font-semibold;
}

.shepherd-text {
  @apply px-5 text-sm pt-3 pb-5;
}

.shepherd-button {
  @apply text-sm text-white rounded-md;
}

.shepherd-cancel-icon {
  @apply text-[28px];
}

.shepherd-arrow:before {
  @apply border-l border-b border-gray-200;
}

.shepherd-element {
  @apply max-w-[500px];
}

.shepherd-button-outline {
  @apply bg-white text-blue-500 border border-blue-500 border-solid;
}

.shepherd-element[data-popper-placement^=left]>.shepherd-arrow {
  @apply rotate-180;
}

.shepherd-enabled.shepherd-element[data-popper-placement^=left] {
  @apply -translate-x-4;
}

.shepherd-button.shepherd-button-secondary {
  @apply bg-white border-0 px-1
}
.p-treenode-label,
.p-treeselect-label {
  @apply text-sm;
}

p-confirmdialog {
  .p-overlaypanel-content {
    @apply p-1 bg-blue-500;
  }

  .p-dialog .p-dialog-header .p-dialog-title {
    @apply text-lg;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    @apply ml-0;
  }

  .p-button-label {
    @apply text-base font-normal;
  }

  .p-confirm-dialog-reject,
  .p-confirm-dialog-accept {
    @apply py-2 px-3 border-0 outline-0 shadow-none;
  }

  .p-confirm-dialog-reject {
    @apply border-gray-500 border hover:border-gray-500 hover:bg-white hover:text-black bg-white text-black;
  }

  .p-confirm-dialog-reject.p-button:enabled:hover {
    @apply border border-gray-500 bg-white text-black border-opacity-100 bg-opacity-100 text-opacity-100;
  }
}

p-confirmdialog {
  &.error,
  &.delete {
    .p-confirm-dialog-accept {
      @apply bg-error-500 hover:bg-error-600;
    }

    .p-confirm-dialog-reject:hover {
      @apply bg-gray-100 text-gray-700 border-gray-700;
    }
  }

  &.reject {
    .p-confirm-dialog-accept {
      @apply bg-primary-500 hover:bg-primary-600;
    }

    .p-confirm-dialog-reject:hover {
      @apply bg-gray-100 text-gray-700 border-gray-700;
    }
  }

  .p-confirm-dialog {
    @apply min-w-[30vw];

    .p-confirm-dialog-message {
      @apply text-sm;
    }

    .p-button-label {
      @apply text-sm;
    }

    .p-confirm-dialog-accept,
    .p-confirm-dialog-reject {
      @apply shadow-none h-[38px];
    }
  }

  .p-dialog .p-dialog-footer button:last-child {
    @apply mr-0;
  }
}

p-dialog {
  .p-dialog .p-dialog-footer button {
    margin: 0 !important;
    margin-left: 8px !important;
  }
}

.p-checkbox-box {
  width: 20px !important;
  height: 20px !important;
}

p-radiobutton {
  .p-radiobutton .p-radiobutton-box.p-highlight {
    @apply bg-white;
  }

  .p-radiobutton,
  .p-radiobutton-box {
    width: 20px !important;
    height: 20px !important;
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    @apply bg-blue-500 w-[10px] h-[10px];
  }

  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    @apply border-blue-500 bg-transparent;
  }

  .p-radiobutton .p-radiobutton-box {
    @apply border;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-dropdown-empty-message {
  @apply text-sm;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  @apply py-2;
}

.p-inputtext {
  font-size: 14px !important;
}

p-multiselect {
  .p-multiselect-label {
    @apply h-11 flex items-center text-sm;
  }
}

p-multiselectitem {
  .p-multiselect-item {
    @apply text-sm;
  }
}

.p-multiselect-empty-message {
  @apply text-sm;
}

.p-multiselect-filter {
  @apply h-10;
}

.p-monthpicker-month,
.p-yearpicker-year {
  @apply text-sm;
}

.p-datepicker table td {
  padding: 4px !important;
}

.p-datepicker table td > span {
  @apply text-sm;
  width: 36px !important;
  height: 36px !important;
}

.p-datepicker table th > span {
  @apply text-sm;
}

.p-datepicker .p-timepicker {
  @apply p-1;
}

.p-datepicker .p-timepicker span {
  font-size: 16px !important;
}

.p-datepicker .p-timepicker button {
  @apply w-7 h-7;
}

.p-calendar .p-inputtext {
  @apply h-11;
}

.p-datepicker .p-datepicker-header {
  padding: 0 !important;
}

p-paginator {
  @apply bg-transparent p-0;

  .p-link {
    @apply text-sm;
  }

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last,
  .p-paginator-pages .p-paginator-page {
    min-width: 2.5rem !important;
    height: 40px !important;
  }
}

p-table {
  .p-datatable .p-datatable-footer {
    @apply p-0;
  }

  .p-datatable .p-datatable-header {
    @apply border-t-0;
  }

  .p-datatable .p-datatable-footer {
    @apply border-b-0;
  }

  tr:last-child > td {
    border: 0 !important;
  }

  td,
  th {
    @apply text-sm font-medium;
  }
}

p-inputswitch {
  .p-inputswitch {
    @apply w-9 h-5;
  }

  .p-inputswitch .p-inputswitch-slider:before {
    @apply w-4 h-4 mt-0 translate-y-[-50%] left-[3px];
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    @apply translate-x-[14px];
  }
}

p-skeleton {
  .p-skeleton {
    @apply bg-gray-200;
  }
}

.p-button {
  .p-button-label {
    transition-duration: 0s !important;
  }
}

p-chips {
  .p-chips-token {
    @apply max-w-[200px] truncate;
  }
}

p-divider {
  .p-divider.p-divider-horizontal {
    @apply m-0;
  }
}

.p-progress-spinner {
  width: 72px !important;
  height: 72px !important;
}

.p-tooltip-text {
  @apply text-sm;
  color: black !important;
  background-color: white !important;
  white-space: unset !important;
}

.p-tooltip-arrow {
  border-right-color: white !important;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: white !important;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: white !important;
}

.p-badge {
  min-width: 14px !important;
  height: 14px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 10px !important;
  top: 6px !important;
  right: 4px !important;
}

.attribute-overplayPanel::before,
.attribute-overplayPanel::after {
  display: none !important;
}

.attribute-overplayPanel > .p-overlaypanel-content {
  padding: 0 !important;
}

p-tree {
  .p-tree {
    @apply p-0;
  }

  .p-tree-empty-message {
    @apply text-sm ml-3;
  }
}

p-fileupload {
  .p-fileupload-choose {
    @apply text-sm;
  }
}

.p-orderlist-list {
  border: 0 !important;
}

.p-orderlist-controls {
  display: none !important;
}

.p-orderlist .p-orderlist-list .p-orderlist-item,
.p-orderlist-item.cdk-drag-preview {
  padding: 0 !important;
}

.p-orderlist-item.cdk-drag-preview {
  height: unset !important;
  overflow: hidden !important;
  border-radius: 6px !important;
}
.p-datepicker {
  border: 0 !important;
}

.ng-invalid-field {
  border: 1px solid #e24c4c;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #fec3bf;
  border-radius: 8px;
}

/* width */
::-webkit-scrollbar {
  @apply w-2 h-[10px];
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  /* @apply bg-blue-200 rounded-md; */
  @apply bg-gray-50 rounded;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888; */
  /* @apply bg-blue-600 rounded-md; */
  @apply bg-gray-200 rounded;
  /* @apply bg-gradient-to-b from-blue-400 to-green-400 rounded-md; */
  &:hover {
    /* background: #555; */
    /* @apply bg-blue-800; */
    @apply bg-gray-300;
  }
}

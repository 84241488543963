/*
  Usage: class="btn btn-[size] btn-[type]"
  Size: xs, s, sm, md, lg, xl
  Type: primary, secondary-gray, secondary-color, destructive-primary, destructive-secondary-gray ...
*/

.btn {
  @apply inline-flex gap-2 justify-center items-center cursor-pointer;

  &-xs {
    @apply text-sm font-semibold py-1 px-3 rounded-md;
  }

  &-s {
    @apply text-sm font-semibold py-[0.375rem] px-3 rounded-md;
  }

  &-sm {
    @apply text-sm font-semibold py-2 px-[0.875rem] rounded-lg;
  }

  &-md {
    @apply text-sm font-semibold py-[0.625rem] px-4 rounded-lg;
  }

  &-lg {
    @apply text-base font-semibold py-[0.625rem] px-[1.125rem] rounded-lg;
  }

  &-xl {
    @apply text-base font-semibold py-[0.75rem] px-[1.25rem] rounded-lg;
  }

  &-primary {
    @apply text-white border border-primary-600 bg-primary-600 hover:border-primary-500 hover:bg-primary-500 active:border-primary-700 active:bg-primary-700 disabled:border-primary-200 disabled:bg-primary-200 disabled:cursor-default;
  }

  &-destructive-primary {
    @apply text-white border border-error-600 bg-error-600 hover:border-error-500 hover:bg-error-500 active:border-error-700 active:bg-error-700 disabled:border-error-200 disabled:bg-error-200 disabled:cursor-default;
  }

  &-secondary-gray {
    @apply text-gray-700 border border-gray-300 bg-white hover:border-gray-300 hover:bg-gray-50 active:border-gray-300 active:bg-gray-200 disabled:opacity-60 disabled:cursor-default;
  }

  &-secondary-primary {
    @apply text-blue-700 border border-blue-700 bg-white hover:bg-blue-50 disabled:cursor-default disabled:hover:bg-white;
  }

  &-destructive-secondary-gray {
    @apply text-error-700 border border-error-300 bg-white hover:border-error-300 hover:bg-error-25 active:border-error-300 active:bg-error-50 disabled:bg-white disabled:border-error-200 disabled:text-error-300 disabled:cursor-default;
  }

  &-secondary-color {
    @apply text-primary-700 border border-primary-50 bg-primary-50 hover:border-primary-100 hover:bg-primary-100 active:border-primary-200 active:bg-primary-200 disabled:border-primary-25 disabled:bg-primary-25 disabled:text-primary-300 disabled:cursor-default;
  }

  &-destructive-secondary-color {
    @apply text-error-700 border border-error-50 bg-error-50 hover:border-error-25 hover:bg-error-25 active:border-error-100 active:bg-error-100 disabled:bg-error-25 disabled:border-error-25 disabled:text-error-300 disabled:cursor-default;
  }
}

.btn-2nd {
  @apply inline-flex gap-2 justify-center items-center cursor-pointer;

  &-xs {
    @apply text-sm font-semibold py-1 px-3 rounded-md;
  }

  &-s {
    @apply text-sm font-semibold py-[0.375rem] px-3 rounded-md;
  }

  &-sm {
    @apply text-sm font-semibold py-2 px-[0.875rem] rounded-lg;
  }

  &-md {
    @apply text-sm font-semibold py-2 px-4 rounded-lg;
  }

  &-lg {
    @apply text-base font-semibold py-[0.625rem] px-[1.125rem] rounded-lg;
  }

  &-xl {
    @apply text-base font-semibold py-[0.75rem] px-[1.25rem] rounded-lg;
  }

  &-outline-primary {
    @apply text-blue-600 border border-blue-600 bg-white hover:bg-blue-50 disabled:cursor-default disabled:hover:bg-white;
  }
}
